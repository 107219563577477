import { Customer } from "./customer";
import { FlyTrip } from "./flyTrip";
import { Order, OrderDetail } from "./order";
import { QueryParam } from "./query";
import { Staff } from "./staff";

export interface ProductPackage {
  id: number;
  no: number;
  createdAt: number;
  updatedAt: number;
  isDeleted: boolean;
  code: string;
  name: string;
  totalWeight: number; //đơn vị lbs (pound)
  type: ProductPackageType;
  children: ProductPackage[];
  parent: ProductPackage;
  orders: Order[];
  flyTrip: FlyTrip;
  types: string;
  status: any;
  productPackageDetails: OrderDetail[];
  order: Order;
  customer: Customer;
  createdStaff: Staff;
  isHiddenFirstStatus: boolean;
}

export interface ProductPackageQuery extends QueryParam {
  type?: ProductPackageType;
  isParent?: boolean;
  flyTripId?: number;
  isAvailable?: boolean;
  fromAt?: number;
  toAt?: number;
  status?: ProductPackageStatus;
}

export enum ProductPackageType {
  CHIAHANG = "CHIAHANG",
  BUON = "BUON",
  MIXED = "MIXED",
  GIFT = "GIFT",
  MASTER = "MASTER",
  // ONLINE = "ONLINE",
  SHIPTUVN = "SHIPTUVN",
  BUONCHIA = "BUON_CHIA",
}

export enum ProductPackageStatus {
  SaveUsa = "SAVE_USA", //1. đã nhập kho USA
  Labeled = "LABELED", //2. đã tạo label
  PrepareFly = "PREPARE_FLY", //3. đã bay
  Fly = "FLY", //3. đã bay
  Arrivaled = "ARRIVALED", //4. đã đến - đã đáp
  SaveVN = "SAVE_VN", //5. đã về kho VN
  DeliveryEMS = "DELIVERY_EMS", //Đã phát qua EMS
  DeliveryProvince = "DELIVERY_PROVINCE", //đã gửi liên tỉnh
  Delivering = "DELIVERING", //6. đang giao
  Complete = "COMPLETE", //7. đã giao
  Delay = "DELAY", //
  Cancel = "CANCEL", //bị hủy
  Stuck = "STUCK", //lưu kho (chưa có ng nhận)
}

export const ProductPackageStatusTrans = {
  [ProductPackageStatus.SaveUsa]: {
    label: "Đã nhập kho US",
    color: "geekblue",
    value: ProductPackageStatus.SaveUsa,
  },
  [ProductPackageStatus.Labeled]: {
    label: "Đã tạo label",
    color: "yellow",
    value: ProductPackageStatus.Labeled,
  },
  [ProductPackageStatus.PrepareFly]: {
    value: ProductPackageStatus.PrepareFly,
    label: "Chuẩn bị bay",
    color: "#f7b011",
  },
  [ProductPackageStatus.Fly]: {
    label: "Đã bay",
    color: "lime",
    value: ProductPackageStatus.Fly,
  },
  [ProductPackageStatus.Arrivaled]: {
    label: "Đã đáp",
    color: "gray",
    value: ProductPackageStatus.Arrivaled,
  },
  [ProductPackageStatus.SaveVN]: {
    label: "Đã thông quan",
    color: "orange",
    value: ProductPackageStatus.SaveVN,
  },
  [ProductPackageStatus.Delivering]: {
    label: "Chuẩn bị giao",
    color: "green",
    value: ProductPackageStatus.Delivering,
  },
  [ProductPackageStatus.Complete]: {
    label: "Đã giao",
    color: "purple",
    value: ProductPackageStatus.Complete,
  },
  [ProductPackageStatus.Delay]: {
    label: "Delay",
    color: "#e06666",
    value: ProductPackageStatus.Delay,
  },
  [ProductPackageStatus.Cancel]: {
    label: "Bị hủy",
    color: "#f91616",
    value: ProductPackageStatus.Cancel,
  },
  [ProductPackageStatus.Stuck]: {
    label: "Lưu kho",
    color: "volcano",
    value: ProductPackageStatus.Stuck,
  },
  [ProductPackageStatus.DeliveryEMS]: {
    label: "Đã phát qua EMS",
    color: "magenta",
    value: ProductPackageStatus.DeliveryEMS,
  },
  [ProductPackageStatus.DeliveryProvince]: {
    label: "Đã gửi liên tỉnh",
    color: "#3d85c6",
    value: ProductPackageStatus.DeliveryProvince,
  },
};

export const productPackageTypeTrans = {
  [ProductPackageType.MASTER]: {
    label: "Master",
    color: "cyan",
    value: ProductPackageType.MASTER,
  },
  [ProductPackageType.CHIAHANG]: {
    label: "Chia hàng",
    color: "gold",
    value: ProductPackageType.CHIAHANG,
  },
  [ProductPackageType.BUONCHIA]: {
    label: "Buôn - Chia hàng",
    color: "green",
    value: ProductPackageType.BUONCHIA,
  },
  [ProductPackageType.BUON]: {
    label: "Buôn",
    color: "purple",
    value: ProductPackageType.BUON,
  },

  [ProductPackageType.GIFT]: {
    label: "Quà tặng",
    color: "geekblue",
    value: ProductPackageType.GIFT,
  },

  [ProductPackageType.MIXED]: {
    label: "Mixed",
    color: "magenta",
    value: ProductPackageType.MIXED,
  },
  // [ProductPackageType.ONLINE]: {
  //   label: "Online",
  //   color: "green",
  //   value: ProductPackageType.ONLINE,
  // },
  [ProductPackageType.SHIPTUVN]: {
    label: "Ship từ VN",
    color: "gray",
    value: ProductPackageType.SHIPTUVN,
  },
};
