import { City, District, Ward } from "./address";
import { Customer } from "./customer";
import { Depot } from "./depot";
import { FlyTrip } from "./flyTrip";
import { Inventory } from "./inventory";
import { Product } from "./product";
import { ProductPackage } from "./productPackage";
import { QueryParam } from "./query";
import { Receiver } from "./receiver";
import { Region } from "./region";
import { Staff } from "./staff";

export interface OrderForm extends Order {
  customerId: number;
  receiverId: number;
  flyTripId: number;
  productPackageIds: number[];
  districtId: number;
  cityId: number;
}

export enum ReceiveType {
  Station = "STATION", //khách tới kho lấy
  Door = "DOOR", //Giao tận nhà
}

export interface OrderQuery extends QueryParam {
  customerId?: number;
  fromDate?: string;
  toDate?: string;
  flyTripId?: number;
  createdStaffId?: number;
  status?: any;
  type?: OrderType;
  isFlyTrip?: boolean;
  isChild?: boolean;
}

export interface OrderLog {
  id: number;
  createdAt: number;
  createdTime: number;
  updatedAt: number;
  isDeleted: boolean;
  description: string; //mô tả về log
  // type: OrderLogType;
  status: string;
  prevOrderDetailJson: string;
  currentOrderDetailJson: string;
  order: Order;
  staff: Staff;
  isVisible: boolean;
}

export interface Order {
  id: number;
  createdAt: number;
  updatedAt: number;
  isDeleted: boolean;
  code: string;
  localCode: string;
  postCode: string;
  note: string; //
  deliveryAt: number; //Thời gian dự kiến giao
  type: OrderType;
  paymentType: PaymentType;
  region: Region;
  totalWeight: number; //đơn vị pound.  < 20lbs → thêm $0.25/lb
  moneyDiscount: number; //tiền giảm giá, chiết khấu
  moneyVat: number; //Tiền thuế
  vatPercent: number; //% thuế
  shipFee: number; //chi phí v/c
  moneyTotal: number; //Tạm tính, tiền hàng hóa
  shipFeeExtra: number; //thu phí thêm nếu vượt cân nặng:  $0.25/lb
  moneyDeposit: number; //tiền khách cọc
  moneyProduct: number;
  moneyTransfer: number; //tiền khách chuyển khoản, hoặc đã trả
  moneyFinal: number; //Tổng tiền khách phải trả
  codVND: number; //thu COD ở VN
  exchangeRate: number; //quy đổi usd->vnd
  cancelReason: string;
  canceledAt: number;
  completedAt: number;
  status: OrderStatus;
  discountType: DiscountType;
  senderName: string;
  senderPhone: string;
  senderAddress: string;
  receiverName: string;
  receiverAddress: string;
  receiverPhone: string;
  subReceiverName: string;
  subReceiverAddress: string;
  subReceiverPhone: string;
  // shipBy: OrderShipBy;
  isChecked: boolean; //true: đã đối soát
  numOfPacks: number; //sl kiện
  proofImage: string; //hình ảnh bằng chứng giao hàng
  receiveType: ReceiveType; //cách nhận hàng
  customer: Customer; //
  orderDetails: OrderDetail[];
  createdStaff: Staff;
  receiver: Receiver;
  subReceiver: Receiver;
  productPackages: ProductPackage[];
  flyTrip: FlyTrip;
  deliveryStaff: Staff;
  orderLogs: OrderLog[];
  depot: Depot;
  city: City;
  district: District;
  ward: Ward;
  parent: Order;
  children: Order[];
  inventories: Inventory[];
  trackingCode: string;
  //nếu dưới 10lbs vẫn tính là 10lbs
  //nhỏ hơn 20lbs thì bị phạt
  // this.moneyDiscount = orderDetails.reduce((prev, cur) => prev + cur.discount, 0)
  discountPercent: number;
  isNoTax: boolean;
  createdDateTime: any;
}

export enum DiscountType {
  Amount = "AMOUNT",
  Percent = "PERCENT",
}

export interface OrderDetail {
  id: number;
  createdAt: number;
  updatedAt: number;
  isDeleted: boolean;
  name: string;
  price: number;
  type: OrderDetailType;
  discount: number; //Tiền giảm giá, chiết khấu
  description: string;
  quantity: number;
  weight: number;
  cWeight: number;

  product: Product;
  order: Order;
  productPackage: ProductPackage;
  key: string;
}

export enum OrderDetailType {
  Product = "PRODUCT", //có product trong kho
  Custom = "CUSTOM", //hàng ký gửi
  SubCharge = "SUB_CHARGE", //phụ phí
}

export interface OrderDetailForm extends OrderDetail {
  productId: number;
  packageId: number;
}

export enum OrderType {
  Receipt = "RECEIPT", //mua tại cửa hàng
  Ship = "SHIP", //ship về vn
}

export enum OrderStatus {
  SaveUsa = "SAVE_USA",
  Labeled = "LABELED", //1. đã tạo label
  PrepareFly = "PREPARE_FLY", //2. đã bay
  Fly = "FLY", //2. đã bay
  Arrivaled = "ARRIVALED", //3. đã đến - đã đáp
  SaveVN = "SAVE_VN", //4. đã về kho VN
  DeliveryEMS = "DELIVERY_EMS", //Đã phát qua EMS
  DeliveryProvince = "DELIVERY_PROVINCE", //đã gửi liên tỉnh
  Delivering = "DELIVERING", //5. đang giao
  Complete = "COMPLETE", //6. đã giao
  Delay = "DELAY", // 7.
  Cancel = "CANCEL", //8. bị hủy
  Stuck = "STUCK", //9. lưu kho (chưa có ng nhận)
}
screenY;
export const orderStatusTrans = {
  [OrderStatus.SaveUsa]: {
    title: "Đã nhập kho US",
    color: "geekblue",
    value: OrderStatus.SaveUsa,
    icon: "/images/icon/warehouse.png",
  },
  [OrderStatus.Labeled]: {
    title: "Đã tạo label",
    color: "yellow",
    value: OrderStatus.Labeled,
    icon: "/images/icon/labeled.svg",
  },
  [OrderStatus.PrepareFly]: {
    title: "Chuẩn bị bay",
    color: "#f7b011",
    value: OrderStatus.PrepareFly,
    icon: "/images/icon/gate2.png",
  },
  [OrderStatus.Fly]: {
    title: "Đã bay",
    color: "lime",
    value: OrderStatus.Fly,
    icon: "/images/icon/fly.svg",
  },
  [OrderStatus.Arrivaled]: {
    title: "Đã đáp",
    color: "gray",
    value: OrderStatus.Arrivaled,
    icon: "/images/icon/arrival.svg",
  },
  [OrderStatus.SaveVN]: {
    title: "Đã thông quan",
    color: "orange",
    value: OrderStatus.SaveVN,
    icon: "/images/icon/VNDepot.svg",
  },
  [OrderStatus.DeliveryEMS]: {
    title: "Đã phát qua EMS",
    color: "magenta",
    value: OrderStatus.DeliveryEMS,
    icon: "/images/icon/ems.png",
  },
  [OrderStatus.DeliveryProvince]: {
    title: "Đã gửi liên tỉnh",
    color: "#3d85c6",
    value: OrderStatus.DeliveryProvince,
    icon: "/images/icon/delivery.png",
  },
  [OrderStatus.Delivering]: {
    title: "Chuẩn bị giao",
    color: "green",
    value: OrderStatus.Delivering,
    icon: "/images/icon/delivery-bike.png",
  },
  [OrderStatus.Complete]: {
    title: "Đã giao",
    color: "purple",
    value: OrderStatus.Complete,
    icon: "/images/icon/success.svg",
  },
  [OrderStatus.Delay]: {
    title: "Delay",
    color: "#e06666",
    value: OrderStatus.Delay,
    icon: "/images/icon/delay.svg",
  },
  [OrderStatus.Cancel]: {
    title: "Bị huỷ",
    color: "#f91616",
    value: OrderStatus.Cancel,
    icon: "/images/icon/cancel.svg",
  },
  [OrderStatus.Stuck]: {
    title: "Lưu kho",
    color: "volcano",
    value: OrderStatus.Stuck,
    icon: "/images/icon/saveDepot.svg",
  },
};

export enum PaymentType {
  Cash = "CASH",
  Bank = "BANK",
  Debit = "DEBIT", //Credit card / debit
  COD = "COD", //pay vn (thanh toán 1 phần, hoặc full)
  Checks = "CHECKS",
  Zelle = "ZELLE",
}

export const PaymentTypeTrans = {
  [PaymentType.Cash]: {
    title: "Tiền mặt",
    color: "blue",
    value: PaymentType.Cash,
  },
  [PaymentType.Bank]: {
    title: "Chuyển khoản",
    color: "cyan",
    value: PaymentType.Bank,
  },
  [PaymentType.Debit]: {
    title: "Thẻ tín dụng",
    color: "geekblue",
    value: PaymentType.Debit,
  },
  [PaymentType.COD]: {
    title: "COD",
    color: "purple",
    value: PaymentType.COD,
  },
  [PaymentType.Checks]: {
    title: "Checks",
    color: "magenta",
    value: PaymentType.Checks,
  },
  [PaymentType.Zelle]: {
    title: "Zelle",
    color: "orange",
    value: PaymentType.Zelle,
  },
};
