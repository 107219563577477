export const settings = {
  checkPermission: false,
  version: "1.0.42",
  dateFormat: "MM/DD/YYYY",
  fullDateFormat: "HH:mm, MM/DD/YYYY",
  hashKey: "DBZUYAEboP",
  trackingLink: "https://249client.bmdapp.store/order-tracking",
  trackingHash: "DBZUYAgyxsdqgEboP",
  googleMapKey: process.env.REACT_APP_GOOGLE_API_KEY,
};
