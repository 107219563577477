import {
  Avatar,
  Button,
  Card,
  Col,
  Divider,
  Form,
  Input,
  List,
  message,
  Row,
} from "antd";
import { useForm } from "antd/lib/form/Form";
import { authApi } from "api/auth.api";
import { configApi } from "api/config.api";
import { GenderTrans } from "constant";
import { useReceiver } from "hooks/useReceiver";
import { useEffect, useState } from "react";
import { userStore } from "store/userStore";
import { getTitle } from "utils";
import { getAddress } from "utils/address";

export const ProfilePage = ({ title }: { title: string }) => {
  const [form] = useForm();
  const [loading, setLoading] = useState(false);
  const [hotline, setHotline] = useState<string>();

  const { receivers, fetchReceiver } = useReceiver();
  useEffect(() => {
    document.title = getTitle(title);
    // fetchHotline();
    fetchReceiver();
  }, []);

  //handle submit form
  const onFinish = async (values: any) => {
    const oldPassword = form.getFieldValue("oldPassword");
    const newPassword = form.getFieldValue("newPassword");
    setLoading(true);

    try {
      const res = await authApi.passwordUpdate({
        oldPassword,
        newPassword,
      });
      form.resetFields();
      message.success("Đã cập nhật mật khẩu của bạn!");
    } finally {
      setLoading(false);
    }
  };
  console.log(receivers);
  return (
    <div className="b-container" style={{ marginTop: "2em" }}>
      {" "}
      <Row gutter={[8, 16]} justify="center" style={{ display: "flex" }}>
        <Col
          className="gutter-row"
          xxl={10}
          xl={10}
          lg={10}
          md={12}
          style={{ margin: "0 auto", width: "100%" }}
        >
          <div className="box">
            <Card
              title="Thông tin cá nhân"
              bordered={false}
              // style={{ padding: 20 }}
            >
              <Row gutter={8}>
                <Col xs={24} md={12}>
                  <ul style={{ fontSize: 14 }}>
                    <li>
                      <b>Customer ID:</b> {userStore?.info?.code}
                    </li>
                    <li>
                      <b>Tên đăng nhập:</b> {userStore?.info?.username}
                    </li>
                    <li>
                      <b>Họ tên:</b> {userStore?.info?.name}
                    </li>
                    <li>
                      <b>Số điện thoại:</b> {userStore?.info?.phone}
                    </li>
                  </ul>
                </Col>

                <Col xs={24} md={12}>
                  <ul style={{ fontSize: 14 }}>
                    <li>
                      <b>Địa chỉ:</b>{" "}
                      {userStore?.info?.address || "Chưa cập nhật"}
                    </li>
                    <li>
                      <b>Email:</b> {userStore?.info?.email || "Chưa có email"}
                    </li>
                    <li>
                      <b>Giới tính:</b>{" "}
                      {userStore?.info?.gender
                        ? GenderTrans[userStore.info.gender]?.label
                        : "Không xác định"}
                    </li>
                  </ul>
                </Col>
              </Row>
            </Card>

            <Card title="Danh sách người nhận" bordered={false}>
              <List
                dataSource={receivers}
                renderItem={(receiver) => (
                  <List.Item
                    style={{
                      padding: "4px 20px",
                      borderBottom: "1px solid #f0f0f0",
                    }}
                  >
                    <div>
                      <div>
                        <b>Họ tên:</b> {receiver.name}
                      </div>
                      <div>
                        <b>Số điện thoại:</b> {receiver.phone}
                      </div>
                      <div>
                        <b>Địa chỉ:</b>{" "}
                        {getAddress(
                          receiver?.ward,
                          receiver?.district,
                          receiver?.city,
                          receiver?.address
                        ) || "Chưa cập nhật"}
                      </div>
                    </div>
                  </List.Item>
                )}
              />
            </Card>
          </div>
        </Col>
        <Col
          className="gutter-row"
          xxl={10}
          xl={10}
          lg={10}
          md={12}
          style={{ margin: "auto", width: "100%" }}
        >
          <div className="box">
            <Card title="Đổi mật khẩu" bordered={false}>
              <Form
                form={form}
                onFinish={onFinish}
                //   onFinishFailed={onFinishFailed}
                layout="vertical"
              >
                <Form.Item
                  label="Mật khẩu cũ"
                  name="oldPassword"
                  required
                  rules={[
                    {
                      required: true,
                      message: "Vui lòng điền mật khẩu của bạn",
                    },
                  ]}
                >
                  <Input.Password />
                </Form.Item>
                <Form.Item
                  name="newPassword"
                  label="Mật khẩu mới"
                  required
                  rules={[
                    {
                      required: true,
                      message: "Vui lòng điền mật khẩu mới của bạn",
                    },
                  ]}
                >
                  <Input.Password />
                </Form.Item>
                <Form.Item
                  name="renewPassword"
                  label="Nhập lại mật khẩu"
                  required
                  rules={[
                    {
                      required: true,
                      message: "Vui lòng nhập lại mật khẩu mới của bạn",
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue("newPassword") === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(new Error("Mật khẩu không khớp"));
                      },
                    }),
                  ]}
                >
                  <Input.Password />
                </Form.Item>
                <Form.Item style={{ marginBottom: 0 }}>
                  <Button
                    loading={loading}
                    type="primary"
                    htmlType="submit"
                    size="middle"
                  >
                    Cập nhật
                  </Button>
                </Form.Item>
              </Form>
            </Card>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export { ProfilePage as default };
