import { Gender } from "constant";
import { City, District, Ward } from "./address";
import { Receiver } from "./receiver";
import { Role, Staff } from "./staff";

export interface Customer {
  id: number;
  createdAt: number;
  updatedAt: number;
  isDeleted: boolean;
  username: string;
  password: string;
  avatar: string;
  code: string;
  name: string;
  gender: Gender; //giới tính
  address: string; //Địa chỉ thường trú
  note: string;
  dob: string; //dob. Format: Y-M-D
  phone: string;
  isVerified: boolean; //true: đã xác thực
  phoneCountryCode: string;
  type: CustomerType;
  email: string;
  identityImageUrl: string;
  resetCode: string;
  resetCodeAt: number;
  isBlocked: boolean;
  // orders: Order[];
  // feedbacks: Feedback[];
  receivers: Receiver[];
  createdStaff: Staff;
  customerCity: City;
  customerDistrict: District;
  customerWard: Ward;
  vnAddress: string;
  role: Role;
  numOfNotifications: number;
  totalDebt: number;
  // ward: Ward;
  // district: District;
  // city: City;
  addressType: CustomerAddressType;
}

export enum CustomerType {
  Retail = "RETAIL", //khách lẻ
  Sale = "SALE", //khách sỉ
  NTE = "NTE",
}

export const customerTag = {
  [CustomerType.NTE]: { title: "NTE", color: "purple" },
  [CustomerType.Retail]: { title: "Khách lẻ", color: "geekblue" },
  [CustomerType.Sale]: { title: "Khách sỉ", color: "cyan" },
};

export enum CustomerAddressType {
  USA = "USA",
  VN = "VN",
}
